<template>
  <div>

    <promo-side-bar-left
      v-if="!edit"
      :isAuthenticated="isAuthenticated"
      @promoGoto="goToPromoPage($event)"
    ></promo-side-bar-left>

    <div class="circular-progress-overlay" v-if="!eventInfo.id">
      <mu-circular-progress class="demo-circular-progress" :size="26"></mu-circular-progress>
    </div>
    <div class="main section section-event-page">
      <div v-if="!edit">

        <div class="section section-hero" ref="imageSection">
          <div class="section-body">
            <div class="title-block">
              <div class="pre-title">{{ eventInfo.event_type.name }}</div>
              <div
                class="title"
                :class="eventTitleClasses"
              >{{ eventInfo.title }}
              </div>
              <div class="subtitle" v-if="eventInfo.id">
                <div class="wrap">
                  <div class="wrap-title">{{ $moment.utc(eventInfo.date_start).format("DD MMM YYYY") }}<span
                    v-if="endDateNeeded()"> - {{ $moment.utc(eventInfo.date_end).format("DD MMM YYYY") }}</span>
                  </div>
                  <!--                  <div class="wrap-subtitle"><span class="watch"></span>{{$moment.utc(eventInfo.date_start).format("HH:mm")}}</div>-->
                </div>
                <div class="wrap">
                  <div class="wrap-title">{{ eventInfo.country.name }}<span
                    v-if="eventInfo.country.name && eventInfo.city.name">,</span>
                    {{ eventInfo.city.name }}
                  </div>
                  <div class="wrap-subtitle" :class="getAddressClassNames()">{{ eventInfo.address }}</div>
                </div>
              </div>
            </div>
            <div class="section-hero-actions">
              <div class="part part-1">
                <div class="logo-box organizer-logo" v-if="eventInfo.org_logo"
                     :style="{ 'background-image' : 'url(' + eventInfo.org_logo + ')' }"></div>
                <div class="logo-box qr">
                  <qrcode-vue class="qr-canvas" :size="104" :value="qrCodeUrl" level="Q"></qrcode-vue>
                </div>
                <!--
                <div class="event-online-statuses">
                  <div class="event-online-status event-online-status-online  event-online-status-inactive">
                    {{ $t('events.page.eventOnline') }}
                  </div>
                  <div class="event-online-status event-online-status-offline event-online-status-active">
                    {{ $t('events.page.eventOffline') }}
                  </div>
                </div>
                -->
              </div>

              <div class="part">

                <button class="control control-button control-button-secondary"
                        v-if="!isAuthenticated && !isAuthLoading"
                        @click="showAuthPopup({
                        isAuthPopupVisible: true,
                         targetRoute: {
                          name: 'promo-page-events-companies',
                           params: {eventId: $route.params.eventId}
                        }})">
                  {{ $t('title.login') }}
                </button>
                <!--                <div class="log-in"  v-if="!isAuthenticated && !isAuthLoading">-->
                <!--                  <a-->
                <!--                    @click="showAuthPopup({isAuthPopupVisible: true, targetRoute: {name: 'promo-page-events-companies', params: {eventId: $route.params.eventId} }})">-->
                <!--                    {{ $t('title.login') }}-->
                <!--                  </a>-->
                <!--                </div>-->
              </div>

              <div class="part part-2" v-if="eventInfo.access_type === 'register'">
                <!--                <div class="i-will-go">-->
                <!--                  <span class="label">{{ $t('events.page.iWillGo') }}</span>-->
                <!--                  <span class="switch-wrap">-->
                <!--                    <mu-switch v-model="eventInfo.personal.is_going" @change="toggleParticipation"></mu-switch>-->
                <!--                  </span>-->
                <!--                </div>-->

                <!--                <div class="access-status ">{{ $t('title.enterByTickets') }}</div>-->
                <div
                  v-if="eventInfo.buy_booth_url || eventInfo.buy_ticket_url"
                  class="button-wrapper"
                >
                  <!--<mu-button round
                             v-if="eventInfo.buy_booth_url"
                             :href="eventInfo.buy_booth_url"
                             class="btn primary"
                             target="_blank"
                             >{{$t('button.requestABooth')}}
                  </mu-button>-->
                  <!--                  <mu-button round-->
                  <!--                             class="btn secondary"-->
                  <!--                             v-if="eventInfo.buy_ticket_url"-->
                  <!--                             :href="eventInfo.buy_ticket_url"-->
                  <!--                             target="_blank"-->
                  <!--                  >-->
                  <!--                    {{ $t('button.buyTickets') }}-->
                  <!--                  </mu-button>-->

                  <button class="control control-button control-button-primary"
                          v-if="eventInfo.buy_ticket_url"
                          :href="eventInfo.buy_ticket_url"
                          target="_blank"
                          @click="showAuthPopup({isAuthPopupVisible: true,
                           targetRoute: {
                            name: 'promo-page-events-companies',
                             params: {eventId: $route.params.eventId}
                          }
                          })">
                    {{ $t('button.buyTickets') }}
                  </button>
                </div>
              </div>

              <div v-else
                   class="part part-2">
                <div class="access-status ">{{ $t('title.freeEnter') }}</div>
                <!--                <div-->
                <!--                  v-if="eventInfo.buy_booth_url || eventInfo.buy_ticket_url"-->
                <!--                  class="button-wrapper"-->
                <!--                >-->
                <!--                  &lt;!&ndash;<mu-button round-->
                <!--                             v-if="eventInfo.buy_booth_url"-->
                <!--                             :href="eventInfo.buy_booth_url"-->
                <!--                             class="btn primary"-->
                <!--                             target="_blank"-->
                <!--                             >{{ $t('button.requestABooth') }}-->
                <!--                  </mu-button>&ndash;&gt;-->
                <!--&lt;!&ndash;                  <mu-button round&ndash;&gt;-->
                <!--&lt;!&ndash;                             class="btn secondary"&ndash;&gt;-->
                <!--&lt;!&ndash;                             v-if="eventInfo.buy_ticket_url"&ndash;&gt;-->
                <!--&lt;!&ndash;                             :href="eventInfo.buy_ticket_url"&ndash;&gt;-->
                <!--&lt;!&ndash;                             target="_blank"&ndash;&gt;-->
                <!--&lt;!&ndash;                  >&ndash;&gt;-->
                <!--&lt;!&ndash;                    {{$t('button.buyTickets')}}&ndash;&gt;-->
                <!--&lt;!&ndash;                  </mu-button>&ndash;&gt;-->
                <!--                </div>-->
              </div>
            </div>
          </div>
        </div>
        <div class="section section-about-event" v-if="showSections.about">
          <div class="section-body">
            <div class="section-title">
              <div class="title">{{ $t('events.page.about_event_title') }}</div>
            </div>
            <!--            <div class="statistics-block">-->
            <!--              <div class="block-wrap">-->
            <!--                <div class="block-item">-->
            <!--                  <div class="title">80 000</div>-->
            <!--                  <div class="subtitle">{{ $t('events.page.attendees') }}</div>-->
            <!--                </div>-->
            <!--                <div class="block-item">-->
            <!--                  <div class="title">1 100</div>-->
            <!--                  <div class="subtitle">{{ $t('events.page.exhibitors') }}</div>-->
            <!--                </div>-->
            <!--                <div class="block-item">-->
            <!--                  <div class="title">6 000</div>-->
            <!--                  <div class="subtitle">{{ $t('events.page.contractsSigned') }}</div>-->
            <!--                </div>-->
            <!--              </div>-->
            <!--            </div>-->
            <div class="section-content ugc">
              <div class="content content-agenda">
                <ul v-if="preparedAgenda.length">
                  <li v-for="(agendaItem, index) in preparedAgenda"
                      :key="index"
                  >{{ agendaItem }}
                  </li>
                </ul>
              </div>
              <div class="content content-descr ws-pre-wrap">{{ eventInfo.descr }}</div>
            </div>
          </div>
        </div>
        <div class="section section-media" v-if="showSections.media">
          <div class="section-body">
            <div class="section-title">
              <div class="title">{{ $t('events.page.media_title') }}</div>
            </div>
            <div class="carousel-wrap">
              <mu-carousel
                :hide-indicators="eventInfo.photos.length < 2"
                :hide-controls="eventInfo.photos.length < 2">
                <mu-carousel-item v-for="(item, index) in eventInfo.photos" :key="index">
                  <img :src="item" alt="img"/>
                </mu-carousel-item>
              </mu-carousel>
            </div>
          </div>
        </div>

        <div class="section section-venue" v-if="showSections.venue">
          <div class="section-body">
            <div class="section-title">
              <div class="title">{{ $t('events.page.venue_title') }}</div>
            </div>
          </div>
        </div>
        <div class="section section-venue-map" v-if="showSections.venue">
          <div class="section-body">
            <div class="section-content">
              <div class="address-badge">
                <div class="badge-title">{{ $t('events.page.location_title') }}</div>
                <div class="badge-section" v-if="eventInfo.venue_title">
                  <div class="badge-title badge-section-title">{{ $t('events.page.venue_title') }}:</div>
                  <div class="badge-section-content">
                    {{ eventInfo.venue_title }}
                  </div>
                </div>
                <div class="badge-section">
                  <div class="badge-title badge-section-title">{{ $t('events.page.address_title') }}:</div>
                  <div class="badge-section-content">
                    {{ eventInfo.address }},
                    {{ eventInfo.country.name }},
                    {{ eventInfo.city.name }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="map-wrapper">
            <location-picker
              v-if="getMapProvider === 'google'"
              :hasPicker="false"
              :address="locationPickerAddress"
              :mapData="eventInfo.map"
            ></location-picker>
            <baidu-map
              v-if="getMapProvider === 'baidu'"
              :country="eventInfo.country"
              :city="eventInfo.city"
              :mapData="eventInfo.map"
            ></baidu-map>
          </div>
        </div>
        <div class="section section-contacts" v-if="showSections.contacts">
          <div class="section-body">
            <div class="section-title">
              <div class="title">{{ $t('events.page.contacts_title') }}</div>
            </div>
            <div class="section-content">
              <div class="content-item" v-if="eventInfo.org_name">
                <div class="title">{{ $t('contacts.info.organizer') }}</div>
                <div class="content">{{ eventInfo.org_name }}</div>
              </div>
              <div class="content-item" v-if="eventInfo.org_phone">
                <div class="title">{{ $t('contacts.info.phone') }}</div>
                <div class="content">{{ eventInfo.org_phone }}</div>
              </div>
              <div class="content-item" v-if="eventInfo.org_email">
                <div class="title">{{ $t('contacts.info.email') }}</div>
                <div class="content">{{ eventInfo.org_email }}</div>
              </div>
              <div class="content-item" v-if="eventInfo.url">
                <div class="title">{{ $t('events.page.url') }}</div>
                <div class="content">{{ eventInfo.url }}</div>
              </div>
            </div>
          </div>
        </div>

      </div>
      <createEvent
        v-else
        editMode="editEvent"
        :eventInfo="eventInfo"
        :eventId="eventInfo.id"
        @cancelEditing="cancelEditing"
      ></createEvent>
      <!--<edit-event v-else
                  @cancelEditing="cancelEditing"></edit-event>//-->

    </div>

  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import createEvent from './CreateEvent';
import LocationPicker from './components/locationPicker/locationPicker';
import baiduMap from './components/locationPicker/baiduMap';
import QrcodeVue from 'qrcode.vue'

import PromoSideBarLeft from '@/_modules/promo/components/side-bar-left/side-bar-left';

export default {
  name: 'event-page',
  components: {
    LocationPicker,
    createEvent,
    baiduMap,
    QrcodeVue,
    PromoSideBarLeft
  },
  computed: {
    ...mapState(
      'eventStore', ['eventInfo', 'showPromoCheckAccessPopup', 'page_status']
    ),
    ...mapGetters({
      isAuthenticated: 'authStore/isAuthenticated',
      isAuthLoading: 'authStore/isLoading'
    }),
    getMapProvider() {
      let map_provider = process.env.VUE_APP_MAP_PROVIDER;
      if (this.$route.query && this.$route.query.mp && this.$route.query.mp === 'baidu') {
        map_provider = 'baidu';
      }
      return map_provider;
    },
    eventTitleClasses() {
      return {
        'title-short': this.eventInfo.title.length <= 30,
        'title-long': (this.eventInfo.title.length > 30) && (this.eventInfo.title.length <= 60),
        'title-very-long': this.eventInfo.title.length > 60
      }
    },
    showSections() {
      return {
        about: (true /* TODO: динамически проверять блок statistics-block */ || this.eventInfo.agendas || this.eventInfo.description),
        media: this.eventInfo.photos,
        venue: this.eventInfo.address,
        contacts: (this.eventInfo.org_email || this.eventInfo.url || this.eventInfo.org_name || this.eventInfo.org_phone)
      }
    },
    locationPickerAddress() {
      return [
        this.eventInfo.address,
        this.eventInfo.country.name,
        this.eventInfo.city.name
      ].filter((x) => x).join(', ');
    }
  },
  watch: {
    eventInfo: {
      deep: true,
      immediate: true,
      handler() {
        this.setEventInfo();
        this.$nextTick(() => {
          if (!this.$refs.imageSection || !this.eventInfo.image) {
            return;
          }
          this.$refs.imageSection.setAttribute('style', `
          background: no-repeat center/100% url('${this.eventInfo.image}');
          `);
        });
        if (this.eventInfo.agendas) {
          this.preparedAgenda = this.prepareAgenda(this.eventInfo.agendas);
        }
      }
    },
    showPromoCheckAccessPopup: {
      immediate: true,
      handler() {
        this.showPromoAccessPopup = this.showPromoCheckAccessPopup;
      }
    }
  },
  data: () => ({
    qrCodeUrl: window.location.href,
    edit: false,
    current: 1,
    personal: {},
    preparedAgenda: [],
    formData: {
      id: null,
      title: null,
      address: null,
      country_id: null,
      city_id: null,
      date_start: null,
      date_end: null,
      categories: null,
      event_type_id: null,
      image: '',
      photos: [],
      email: '',
      phone: '',
      organizer: '',
      descr: '',
      agendas: '',
      url: null,
    },
    info: { color: '#000' },
    newspaper: { color: '#000' },
    tickets: { color: '#000' },
    hall: { color: '#000' },
    feed: { color: '#000' },
    live: { color: '#000' },
    contacts: { color: '#000' },
    calendar: { color: '#000' },
    notes: { color: '#000' },
    result: { color: '#000' },
    goto: '',
    showPromoAccessPopup: false,
    gotoPromoPageValue: null,
  }),
  created() {
    // this.$store.dispatch('eventStore/event', this.$route.params.eventId);
  },
  mounted() {
    document.addEventListener('scroll', this.monitorHeadPanel);
    this.checkPromoCheckPopup();
  },
  destroyed() {
    document.removeEventListener('scroll', this.monitorHeadPanel)
  },
  methods: {

    async checkPromoCheckPopup() {
      if (this.showPromoCheckAccessPopup === true && this.page_status !== 'loading') {
        await this.showAuthPopup();
      }
    },

    async showAuthPopup(payload) {
      const defaultPayload = { isAuthPopupVisible: true, targetRoute: null };
      const { isAuthPopupVisible, targetRoute } = (payload ? payload : defaultPayload); // types: boolean, Route
      await this.$store.dispatch('authStore/setAuthPopupVisible', { isAuthPopupVisible, targetRoute });
    },

    getAddressClassNames() {
      if (!this.eventInfo.address) {
        return;
      }
      const len = this.eventInfo.address.length;
      // decrease in font-size for longer addresses
      return {
        'wrap-subtitle-textlength-1': len > 43 && len < 100,
        'wrap-subtitle-textlength-2': len >= 100,
      }
    },

    prepareAgenda(str) {
      if (!str) return [];
      return str.split(/(?:\r\n|\r|\n)/).filter((x) => x);
    },
    monitorHeadPanel() {
      // TODO: change accordingly, new design
      // if (window.scrollY > 400) {
      //   this.$refs.sectionHeadPanel.classList.add('section-head-panel-shown');
      // } else {
      //   this.$refs.sectionHeadPanel.classList.remove('section-head-panel-shown');
      // }
    },
    toggleParticipation() {
      this.$store.dispatch('eventStore/toggleParticipation', {
        event_id: this.$route.params.eventId,
        going: this.eventInfo.personal.is_going
      });
    },
    cancelEditing(eventId) {
      if (typeof eventId !== 'undefined') {
        this.$store.dispatch('eventStore/event', eventId);
      }
      this.edit = false;
    },
    editMode() {
      this.edit = !this.edit;
    },
    addFavorite() {
      let eventId = Number(this.$route.params.eventId);

      Promise.all([
        this.$store.dispatch('eventStore/addFavorite', eventId)
      ]).then(() => {
        this.$store.dispatch('eventStore/event', this.$route.params.eventId)
      });


    },
    deleteFavorite() {
      let eventId = Number(this.$route.params.eventId);

      Promise.all([
        this.$store.dispatch('eventStore/deleteFavorite', eventId)
      ]).then(() => {
        this.$store.dispatch('eventStore/event', this.$route.params.eventId)
      });

    },
    setEventInfo() { //TODO: remove form data, get from store
      this.formData.title = this.eventInfo.title;
      if (this.eventInfo.address) {
        this.formData.address = this.eventInfo.address;
      }
      if (this.eventInfo.country) {
        this.formData.country_id = this.eventInfo.country.name;
      }
      if (this.eventInfo.city) {
        this.formData.city_id = this.eventInfo.city.name;
      }
      if (this.eventInfo.date_start) {
        this.formData.date_start = this.eventInfo.date_start;
      }
      if (this.eventInfo.date_end) {
        this.formData.date_end = this.eventInfo.date_end;
      }
      if (this.eventInfo.categories) {
        this.formData.categories = this.eventInfo.categories.map(item => item);
      }
      if (this.eventInfo.event_type) {
        this.formData.event_type_id = this.eventInfo.event_type.name;
      }
      if (this.eventInfo.image) {
        this.formData.image = this.eventInfo.image;
      }
      if (this.eventInfo.photos) {
        this.formData.photos = this.eventInfo.photos.map(item => item);
      }
      if (this.eventInfo.descr) {
        this.formData.descr = this.eventInfo.descr;
      }
      if (this.eventInfo.agendas) {
        this.formData.agendas = this.eventInfo.agendas
      }
      if (this.eventInfo.url) {
        this.formData.url = this.eventInfo.url;
      }
      if (this.eventInfo.personal) {
        this.personal = this.eventInfo.personal;
      }
      if (this.eventInfo.access_type) {
        this.formData.access_type = this.eventInfo.access_type;
      }
    },
    mouseover(event) {

      let target = event.target.id;
      let hoverColor = '#00b6f8';

      switch (target) {
        case 'icon-info':
          this.info.color = hoverColor;
          break;
        case 'icon-newspaper':
          this.newspaper.color = hoverColor;
          break;
        case 'icon-tickets':
          this.tickets.color = hoverColor;
          break;
        case 'icon-hall':
          this.hall.color = hoverColor;
          break;
        case 'icon-live':
          this.live.color = hoverColor;
          break;
        case 'icon-contacts':
          this.contacts.color = hoverColor;
          break;
        case 'icon-calendar':
          this.calendar.color = hoverColor;
          break;
        case 'icon-note':
          this.notes.color = hoverColor;
          break;
        case 'icon-result':
          this.result.color = hoverColor;
          break;
        default:
          break;
      }
    },
    mouseleave() {

      this.info.color = '#000';
      this.newspaper.color = '#000';
      this.feed.color = '#000';
      this.tickets.color = '#000';
      this.hall.color = '#000';
      this.live.color = '#000';
      this.contacts.color = '#000';
      this.calendar.color = '#000';
      this.notes.color = '#000';
      this.result.color = '#000';

      if (this.goto === 'events') {
        this.live.color = '#00b6f8';
      }
      if (this.goto === 'contacts') {
        this.contacts.color = '#00b6f8';
      }
    },
    async goToPromoPage(value) {

      this.gotoPromoPageValue = value;

      this.$store.dispatch('eventStore/hidePromoCheckAccessPopup');

      if (this.eventInfo.personal.has_access !== true) {

        // Default route when navigating from the sideBar
        let targetRoute = { // type: Route
          name: 'promo-page-events-companies',
          params: { eventId: this.$route.params.eventId }
        };

        let redirectParams = null;

        switch (this.gotoPromoPageValue) {
          case 'events':
            redirectParams = {
              name: 'promo-page-events-companies',
              params: { eventId: this.$route.params.eventId }
            };
            break;
          case 'program':
            redirectParams = {
              name: 'promo-program',
              params: { eventId: this.$route.params.eventId }
            };
            break;
          case 'live':
            redirectParams = {
              name: 'promo-live',
              params: { eventId: this.$route.params.eventId }
            };
            break;
          case 'contacts':
            redirectParams = {
              name: 'promo-page-events-contacts',
              params: { eventId: this.$route.params.eventId }
            };
            break;
          case 'messages':
            redirectParams = {
              name: 'promo-page-events-contacts-messages',
              params: { eventId: this.$route.params.eventId }
            };
            break;
          case 'calendar':
            redirectParams = {
              name: 'promo-page-calendar',
              params: { eventId: this.$route.params.eventId }
            };
            break;
          case 'notes':
            redirectParams = {
              name: 'notes-list',
              params: { eventId: this.$route.params.eventId }
            };
            break;
          case 'result':
            redirectParams = {
              name: 'result',
              params: { eventId: this.$route.params.eventId }
            };
            break;
          default:
            break;
        }

        if (redirectParams) {
          targetRoute = redirectParams;
        }

        /* AW-1178.
         * On free events an authenticated user has_access === false until he enters the event for the first time
         * In that case, authPopup was shown for a brief moment.
         * This is against that unwanted behaviour.
         */
        if (this.isAuthenticated && this.eventInfo.access_type === 'free') {
          await this.$store.dispatch('eventStore/toggleParticipation', {
            event_id: this.$route.params.eventId,
            going: true
          });

          this.promoAccessGranted();
          return;
        }

        this.showAuthPopup({
          isAuthPopupVisible: true,
          targetRoute: targetRoute
        });

        this.showPromoAccessPopup = true;

        return; // This return is needed so that promoAccessGranted() is not called

      }
      this.promoAccessGranted();
    },
    endDateNeeded() {
      return !(this.$moment(this.eventInfo.date_start).format("LL") === this.$moment(this.eventInfo.date_end).format("LL"));
    },
    promoAccessGranted() {
      this.showPromoAccessPopup = false;
      switch (this.gotoPromoPageValue) {
        case 'events':
          this.$router.push({
            name: 'promo-page-events-companies',
            params: { eventId: this.$route.params.eventId }
          }).catch(() => {
          });
          break;
        case 'program':
          this.$router.push({
            name: 'promo-program',
            params: { eventId: this.$route.params.eventId }
          }).catch(() => {
          });
          break;
        case 'live':
          this.$router.push({
            name: 'promo-live',
            params: { eventId: this.$route.params.eventId }
          }).catch(() => {
          });
          break;
        case 'contacts':
          this.$router.push({
            name: 'promo-page-events-contacts',
            params: { eventId: this.$route.params.eventId }
          }).catch(() => {
          });
          break;
        case 'messages':
          this.$router.push({
            name: 'promo-page-events-contacts-messages',
            params: { eventId: this.$route.params.eventId }
          }).catch(() => {
          });
          break;
        case 'calendar':
          this.$router.push({
            name: 'promo-page-calendar',
            params: { eventId: this.$route.params.eventId }
          }).catch(() => {
          });
          break;
        case 'notes':
          this.$router.push({
            name: 'notes-list',
            params: { eventId: this.$route.params.eventId }
          }).catch(() => {
          });
          break;
        case 'result':
          this.$router.push({
            name: 'result',
            params: { eventId: this.$route.params.eventId }
          }).catch(() => {});
          break;
        default:
          break;
      }

    }
  }
}
</script>

<style scoped lang="scss">
.mu-button {
  text-decoration: none !important;
}

.section:last-child {
  padding-bottom: 10rem;
}

.section-head-panel {
  position: fixed;
  width: 100%;
  top: -200px;
  left: 0;
  z-index: $z-index-head-panel;
  transition: top 0.5s;
  background-color: $biletum-white;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.29);

  &-shown,
  &-sticky {
    top: 0 !important;
  }

  &-sticky {
    position: sticky !important;
  }

  .section-body {
    padding: 0;
  }
}

.head-panel {
  height: 85px;
  opacity: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .button {
    background-color: $biletum-light-blue;
    color: $biletum-white;

    &:hover,
    &:focus {
      color: #fff;
    }
  }

  .panel-item {
    width: 20%;

    .panel-btn {
      margin: 4px;
    }

    .favorite {
      color: $biletum-light-blue;
    }
  }

  .panel-item-actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-family: Point, sans-serif;
    font-weight: bold;
    font-size: 2.3rem;
  }

}

.section-event-page .section-body {
  max-width: 910px;
  margin: 0 auto;
  padding: 20px 0;
  width: 100%;
}

.section-content {
  .title {
    margin-top: 20px;
  }

  .content {
    margin-top: 10px;
  }
}

.section-event-page {
  margin: 0 auto 0 60px;
  padding-left: 0;
  padding-right: 0;

  .section-title {
    text-align: left;
    padding-top: 8rem;
    margin-bottom: 7rem;

    .title {
      font-family: Point, "Point Regular", sans-serif;
      font-size: 36px;
      color: $biletum-black;
      position: relative;
    }

    .title::before {
      content: "";
      display: block;
      position: absolute;
      z-index: 2;
      border-bottom: 1px solid $biletum-black;
      width: 500%;
      bottom: 10px;
      left: -500%;
      opacity: 0.2;
    }
  }

  .section-content {
    font-family: Point, "Point Regular", sans-serif;
    font-size: 16px;
    color: $biletum-black;
    text-align: left;
    margin-top: 60px;
  }
}

.section-hero {
  .section-body {
    padding: 124px 0 90px;
    z-index: 5;
  }

  &::after {
    display: block;
    content: "";
    width: 100%;
    height: 100%;
    background-color: $biletum-black;
    opacity: 0.7;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
  }


  .title-block {
    .pre-title {
      text-align: left;
      font-family: Point, "Point Regular", sans-serif;
      letter-spacing: 0;
      color: $biletum-yellow;
    }

    .title {
      text-align: left;
      font-family: Point, "Point Regular", sans-serif;
      font-weight: bold;
      color: $biletum-white;
      opacity: 1;
      line-height: 1.2;
      margin-bottom: 0.5em;

      &-short {
        font-size: 8rem;
      }

      &-long {
        font-size: 6.5rem;
      }

      &-very-long {
        font-size: 3.8rem;
      }
    }

    .subtitle {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      > .wrap:first-child {
        padding-right: 20px;
      }

      .wrap-title {
        text-align: left;
        font-family: Point, "Point Regular", sans-serif;
        font-size: 27px;
        letter-spacing: 0;
        color: $biletum-white;
        opacity: 1;
      }

      .wrap-title:first-child {
        text-align: right;
      }

      .wrap-subtitle {
        text-align: left;
        font-family: Point, "Point Regular", sans-serif;
        font-size: 1.9rem;
        letter-spacing: 0;
        color: $biletum-white;
        opacity: 0.8;
        word-break: break-word;
        word-wrap: break-word;

        &-textlength-1 {
          font-size: 1.8rem;
        }

        &-textlength-2 {
          font-size: 1.7rem;
        }

      }
    }
  }

  &-actions {
    padding-top: 7.2rem;
    color: #fff;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .logo-box {
      width: 120px;
      height: 120px;
      border-radius: 15px;
      display: inline-block;
      vertical-align: middle;
      margin-right: 2.4rem;
      overflow: hidden;
      background-color: #ccc;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
    }

    .event-online-statuses {
      display: inline-block;
      font-size: 2.6rem;

      & > :first-child {
        margin-bottom: 2.1rem;
      }
    }

    .event-online-status-inactive {
      color: #fff;
      opacity: 0.5;
    }

    .event-online-status-active,
    .i-will-go .label {
      color: #fc8887;
    }

    .i-will-go {
      border-radius: 15px;
      background-color: rgba(255, 255, 255, 0.3);
      padding: 1.7rem;
      display: flex;
      flex-direction: row;
      align-items: center;

      .label {
        margin-right: 2.1rem;
        font-size: 2.3rem;
      }

      .switch-wrap {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }

    .logo-box.qr {
      background-color: #fff;
      position: relative;

      .qr-canvas {
        width: calc(100% - 16px);
        height: calc(100% - 16px);
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        overflow: hidden;

        canvas {
          width: 100%;
          height: auto;
          max-width: 100%;
        }
      }

      &::after {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 36px;
        height: 32px;
        background-color: #fff;
        background-image: url("../assets/images/qr-overlay-icon.svg");
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        z-index: 2;
        transform: translate(-50%, -50%);
        content: "";
      }
    }

    .organizer-logo {
      background-position: center;
      background-size: contain;
    }
  }

  .button-wrapper {
    display: flex;
    justify-content: flex-end;
  }

  .btn {
    width: 238px;
    height: 44px;
    color: $biletum-white;
    font-size: 2.2rem;
    text-transform: none;
    line-height: 1;
    margin-left: 3rem;

    &:hover {
      color: $biletum-white;
    }
  }

  .primary {
    background-color: $biletum-red;
  }

  .secondary {
    background-color: $biletum-light-blue;
  }
}

.section-about-event {
  .section-content {
    font-family: Point, "Point Regular", sans-serif;
    font-size: 1.6rem;
    color: $biletum-black;
    text-align: left;
  }

  .ugc {
    p,
    ul,
    ol {
      margin-bottom: 1.5rem;
    }

    li {
      margin-bottom: 0.75rem;
    }
  }

  .content-agenda {
    margin-bottom: 6rem;
    word-break: break-word;

    ul {
      margin-left: 0;
      padding-left: 0;
    }
  }

}

.statistics-block {
  width: 1088px;
  height: 254px;
  background: $biletum-white 0 0 no-repeat padding-box;
  box-shadow: 30px 30px 80px #3754aa1a;
  border-radius: 25px;
  opacity: 1;
  margin: 80px auto;
  left: -10%;
  position: relative;
  display: flex;

  .block-wrap {
    width: 860px;
    height: 176px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: auto;

    .block-item {
      border-left: 1px solid #3754aa1a;
      border-right: 1px solid #3754aa1a;
      width: 100%;

      .title {
        text-align: center;
        font-size: 50px;
        font-family: Point, "Point Regular", sans-serif;
        letter-spacing: 0;
        color: $biletum-black;
      }

      .subtitle {
        text-align: center;
        font-size: 25px;
        font-family: Point, "Point Regular", sans-serif;
        letter-spacing: 0;
        color: $biletum-black;
      }
    }
  }
}

.footer-nav,
.push {
  height: 50px;
}

.footer-nav {
  position: fixed;
  bottom: 0;
}

.address-badge {
  display: inline-block;
  margin-bottom: 20rem;
  position: relative;
  z-index: 20;
  font-size: 1.2rem;
  line-height: 1.5rem;
  background-color: $biletum-white;
  border-radius: 25px;
  padding: 17px 24px 24px;
  box-shadow: 30px 30px 80px rgba(55, 84, 170, 0.1);

  .badge-title,
  .badge-section-title {
    font-weight: bold;
    text-transform: uppercase;
  }

  .badge-title,
  .badge-section {
    margin-bottom: 1em;
  }

  .badge-section-content {
    word-break: break-word;
    word-wrap: break-word;
  }

  .badge-section:last-child {
    margin-bottom: 0;
  }

}

.section-media {
  .section-title {
    margin-bottom: 2.4rem;
  }

  .carousel-wrap {
    margin: 0 auto;

    img {
      position: absolute;
      object-fit: contain;
      width: 100%;
      height: 100%;
    }
  }
}

.section-venue {
  .section-body {
    padding-bottom: 0;
  }
}

.section-venue-map {
  .map-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
  }
}

.head-panel .i-will-go {
  padding: 0;
  border-radius: 0;
  background-color: transparent;
  color: #ffa087;
  font-weight: normal;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 5rem;

  .label {
    margin-right: 1.8rem;
    font-size: 1.8rem;
  }

  .switch-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.circular-progress-overlay {
  position: fixed;
  z-index: 99;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  transition: opacity 1s;
}

.part-2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}

.access-status {
  color: $biletum-yellow;
}

.control-button-primary,
.control-button-secondary {
  height: 40px;
  font-size: 16px;
}

</style>
