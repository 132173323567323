<template>
  <div class="view-map view-map-baidu" ref="map" id="baiduMap"></div>
</template>

<script>
import {startBaiduMaps} from "../../../main";

export default {
  name: "baiduMap",
  props: {
    mapData: {
      type: Object
    },
    country: {
      type: Object
    },
    city: {
      type: Object
    }
  },
  data() {
    return {}
  },
  mounted() {
    /* Load Baidu Maps API via a promise */
    if (this.mapData.Latitude && this.mapData.Longitude) {
      startBaiduMaps().then(this.loadBaiduMaps); // main.js
    }
  },
  methods: {
    /* @param baiduMapsAPI = window.BMap
       */
    loadBaiduMaps(baiduMapsAPI) {

      // Docs (in Chinese): https://lbsyun.baidu.com/cms/jsapi/reference/jsapi_reference.html#a3b3
      /* Thank you, es-linter no-undef rule
         * Had to read constants from Baidu JS source.
         */
      const BMAP_ANCHOR_TOP_RIGHT = 1;
      const BMAP_NAVIGATION_CONTROL_ZOOM = 3;
      /******/

      if (!baiduMapsAPI) {
        return;
      }

      let map = new baiduMapsAPI.Map(this.$refs.map.getAttribute('id'));

      let point = new baiduMapsAPI.Point(this.mapData.Longitude, this.mapData.Latitude);
      map.centerAndZoom(point, 16);

      map.addControl(new window.BMap.NavigationControl({
        anchor: BMAP_ANCHOR_TOP_RIGHT,
        type: BMAP_NAVIGATION_CONTROL_ZOOM
      }));

      /* Marker */
      let customMarker = {};
      // uncomment to enable custom marker
      // TODO: correct path to the marker image. Thanks, Webpack.
      //customMarker = {icon: new baiduMapsAPI.Icon('baidu-map-marker.png', new baiduMapsAPI.Size(40, 50))};
      map.addOverlay(new baiduMapsAPI.Marker(point, customMarker));

    }
  }

}
</script>

<style scoped>
  .view-map {
    height: 100%;
  }
</style>
